<template>
  <div class="personal_page" v-if="is_show">
    <HeadNav/>
    <header class="df fdc alc ">
      <div class="upload-box">
        <img class="head-img" :src="head_img" alt="head_img">
        <input type="file" @change="doUpload($event)" accept="image/*">
      </div>
      <span style="margin-top: 0.1rem;">修改头像</span>
    </header>
    <yd-cell-group>
      <div class="cell">
        <span class="form-label">昵称</span>
        <input type="text" v-model="nick_name" placeholder="请输入昵称" maxlength="20" style="width:74%;">
      </div>
      <!--<yd-cell-item class="df alc">
        <span slot="left">昵称</span>
        <input type="text" v-model="nick_name" placeholder="请输入昵称" maxlength="10">
                <yd-input slot="right" v-model="nick_name" placeholder="请输入昵称" max="10"  :show-success-icon="false" :show-error-icon="false" :show-clear-icon="false"></yd-input>
      </yd-cell-item>-->

      <yd-cell-item v-if="mobile">
        <span slot="left" v-if="!fk_show">卡号</span>
        <span slot="left" v-else>手机号</span>
        <yd-input slot="right" v-model="mobile" regex="mobile" :disabled="true" placeholder="手机号码"
                  :show-success-icon="false" :show-error-icon="false"></yd-input>
      </yd-cell-item>
    </yd-cell-group>
    <div class="save-box" @click="editUserInfo">
      <div class="save-btn">保存</div>
    </div>

  </div>
</template>

<script>
import {getAliOssConfigInfo, editUserInfo} from "@/services/my";
import HeadNav from "@/components/common/HeadNav";
import {client} from '@/utils/alioss';
import { getStore } from "@/utils/common";
import { customAccount } from "@/utils/custom";
import SparkMD5 from "spark-md5";
export default {
  name: "PersonalInformation",
  data() {
    return {
      nick_name: '',//昵称
      mobile: '',//手机号码
      head_img: require('../../../assets/ic_head_default@3x.png'),//头像
      id: 'upload',
      url: '',//图片链接
      year: '',
      month: '',
      fk_show: true,
      is_show:false,
      sign: ''
    };
  },
  components: {
    HeadNav
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 判断是否有token
      if (getStore('session_id') === null && customAccount('VUE_APP_JY')) {
        vm.$dialog.loading.open('正在跳转登录...');
        setTimeout(() => {
            vm.$dialog.loading.close();
            vm.$router.replace({
              path:'login'
            })
        }, 1500);
        return false
      } else {
        vm.is_show = true
      }
    })
  },
  mounted() {
    this.getParams();
  },
  methods: {
    //饭卡商城显隐
    isFk(){
        if (process.env.VUE_APP_FK) {
        let bids = process.env.VUE_APP_FK.split(',');
        bids.forEach( val => {
          if (val == JSON.parse(getStore('businessInfo')).id) {
            this.fk_show = false;
            return;
          }
        });
      }
    },
    getParams() {
      this.mobile = this.$store.state.userInfo.mobile ? this.$store.state.userInfo.mobile : '';
      this.nick_name = this.$store.state.userInfo.nickname ? this.$store.state.userInfo.nickname : '';
      this.head_img = this.$store.state.userInfo.heagurl ? this.$store.state.userInfo.heagurl : require('../../../assets/ic_head_default@3x.png');
      this.url = this.$store.state.userInfo.heagurl ? this.$store.state.userInfo.heagurl : '';
    },
    /**
     * 头像上传
     * @param event
     * @returns {Promise<void>}
     */
    async doUpload(event) {
      const _this = this;
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function(e) {
        _this.sign = SparkMD5.hashBinary(e.target.result) || '';
      };
      if (file.type.split('/')[0] !== 'image') {
          this.$dialog.toast({
            mes: '请上传图片',
            icon: 'failure',
            timeout: 1500
          });
          return false
      }
      this.$dialog.loading.open('上传中...');
      if (file) {
        let sign = _this.signature();
        let name = `Uploads/image/${sign}/${new Date().getTime()}${file.name}`;
        // 上传
        try {
          let clientObj = await client();
          let result = await clientObj.put(name, file, {
            meta: {sign: _this.sign}
          });
          _this.head_img = result.url;
          _this.url = result.url;
          this.$dialog.loading.close();
          this.$dialog.toast({
            mes: '成功',
            icon: 'success',
            timeout: 1500
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.$dialog.toast({
          mes: '失败',
          icon: 'failure',
          timeout: 1500
        });
      }

    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
    signature() {
      let date = new Date();
      let y = date.getFullYear();
      let m = this.appendZero(date.getMonth() + 1);
      return `${y}-${m}`;
    },

    /**
     * 编辑个人信息
     * @returns {Promise<void>}
     */
    async editUserInfo() {
      try {
        const res = await editUserInfo(this.url, this.nick_name, this.sign)
        if (res.code === 0) {
          this.$dialog.toast({
            mes: '保存成功',
            icon: 'success',
            timeout: 1500
          });
          this.$store.dispatch('getUserInfo');
          this.$router.go(-1)
        } else {
			this.$dialog.toast({
			  mes: res.msg,
			  icon: 'error',
			  timeout: 1500
			});
		}
      } catch (e) {

      }
    },
  },
  created() {
    this.isFk();
  }
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.personal_page {
  height: 100vh;
  background: @color-white;

  .fdc {
    margin-top: 0.2rem;
  }

  header {
    .sc(0.24rem, @color-grey);
    margin-bottom: 0.78rem;

    .upload-box {
      position: relative;

      input {
        position: absolute;
        .wh(1.56rem, 1.9rem);
        right: 0;
        top: 0;
        opacity: 0;
      }

      .head-img {
        .wh(1.56rem, 1.56rem);
        border-radius: 50%;
      }
    }

  }

  .cell {
    .wh(100%, 0.88rem);
    line-height: 0.88rem;
    margin-left: .24rem;
    border-bottom: 1px solid #D8D8D8;
    overflow: hidden;
    span{
      .sc(0.32rem, #000);
      width: 1.28rem;
      display: inline-block;
    }
    input{
      height: 0.88rem;
      .sc(0.32rem, #666);
    }
  }

  .yd-cell-left {
    width: 1.28rem;
    .sc(0.32rem, #000);
    line-height: 0.88rem;

  }

  .yd-cell-right {
    height: 0.88rem;
    width: 70%;

    span {
      .sc(0.3rem, @color-light-grey)
    }
  }

  .yd-cell {
    overflow: hidden;
  }

  .yd-cell:after {
    margin-left: .24rem;
  }

  .yd-cell-right input[type=text] {
    .sc(0.32rem, @color-grey);
  }

  /*手机号码*/

  input:disabled {
    .sc(0.32rem, #999);
  }

  .save-box {
    .wh(100%, 0.88rem);
    padding: 0 0.24rem;
    margin-top: 1.58rem;
  }

  .save-btn {
    .wh(100%, 100%);
    background: var(--main-color);
    border-radius: 1rem;
    line-height: 0.88rem;
    .sc(0.32rem, #fff);
    text-align: center;
  }
}

</style>
